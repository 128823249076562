import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import httpApi from '@/libs/api'
import Viewer from 'v-viewer'
import * as fundebug from 'fundebug-javascript'
import FundebugVue from 'fundebug-vue' // Vue 3.x
import router from './router'
import store from './store'
import App from './App.vue'
// eslint-disable-next-line
import 'viewerjs/dist/viewer.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'

fundebug.apikey = '5a44717c85fe318694be4a7962ff7e5e43d2518648595ad15f1ea29ce1878a4d'
new FundebugVue(fundebug).installVueErrorHandler(Vue) // Vue 2.x

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source',
  },
})

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$api = httpApi
