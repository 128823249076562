import http from '@/libs/request'

const baseUrl = 'https://api.ebaoguo.net'
// const baseUrl = 'http://43.154.96.126:8082/api'
// const testUrl = 'http://43.154.96.126:8082/api'
const testUrl = 'https://agentapi.ebaoguo.com/api'
// const mockUrl = 'http://127.0.0.1:4523/mock/1203791'

export default {
  // login
  login(params) {
    return http.post(`${testUrl}/login`, params)
  },
  getUser(params) {
    return http.get(`${testUrl}/getUser`, params)
  },
  countryList(params) {
    return http.get(`${baseUrl}/api/countryList`, params)
  },
  courierList(params) {
    return http.get(`${testUrl}/courier/by-agent`, params)
  },
  // shipment
  shipmentList(params) {
    return http.get(`${testUrl}/shipment_list`, params)
  },
  shipmentDetail(params) {
    return http.get(`${testUrl}/shipment_detail`, params)
  },
  getTrackInfo(params) {
    return http.get(`${testUrl}/track_info`, params)
  },
  modifyRecipientOfShipment(params) {
    return http.post(`${testUrl}/modify_recipient_of_shipment`, params, true)
  },
  modifyShipmentPackages(params) {
    return http.post(`${testUrl}/modify_shipment_packages`, params, true)
  },
  modifyShipmentExtraService(params) {
    return http.post(`${testUrl}/modify_shipment_extra_service`, params, true)
  },
  deleteShipment(params) {
    return http.get(`${testUrl}/delete_shipment`, params)
  },
  modifyDeclareOfShipment(params) {
    return http.post(`${testUrl}/modify_declare_of_shipment`, params, true)
  },
  updateShipmentCourier(params) {
    return http.post(`${testUrl}/update_shipment_courier`, params, true)
  },
  statisticsAgentAmount(params) {
    return http.get(`${testUrl}/statistics_agent_amount`, params)
  },
  // parcel
  packageTags(params) {
    return http.get(`${testUrl}/package/tags`, params)
  },
  packageTypes(params) {
    return http.get(`${testUrl}/package/types`, params)
  },
  // submit
  verifyPreshipment(params) {
    return http.get(`${testUrl}/verify_preshipment`, params)
  },
  predictionInfo(params) {
    return http.get(`${testUrl}/prediction_info`, params)
  },
  predictionLogistics(params) {
    return http.post(`${testUrl}/prediction_logistics`, params)
  },
  autoPostcodeSuburb(params) {
    return http.get(`${baseUrl}/api/autoPostcodeSuburb`, params)
  },
  stateList(params) {
    return http.get(`${baseUrl}/api/stateList`, params)
  },
  submitPreshipment(params) {
    return http.post(`${testUrl}/submit_preshipment`, params)
  },
  deleteParcel(params) {
    return http.get(`${testUrl}/delete_parcel`, params)
  },
  createShipment(params) {
    return http.post(`${testUrl}/create_shipment`, params)
  },
  shipmentPrint(params) {
    return http.get(`${testUrl}/shipment_print`, params)
  },
  createLineClear(params) {
    return http.get(`${testUrl}/create_line_clear`, params)
  },
  modifyAgentInfo(params) {
    return http.post(`${testUrl}/modify_agent_info`, params)
  },
  resetPassword(params) {
    return http.post(`${testUrl}/reset_password`, params)
  },
  // seatrans
  seaShipment(params) {
    return http.get(`${testUrl}/sea_shipment`, params)
  },
  confirmPickup(params) {
    return http.post(`${testUrl}/confirm_pickup`, params)
  },
  // setting
  agentInfo(params) {
    return http.get(`${testUrl}/agent_info`, params)
  },
  updateAgentBusinessTime(params) {
    return http.post(`${testUrl}/update_agent_business_time`, params)
  },
  // statistics
  statisticsList(params) {
    return http.post(`${testUrl}/statistic_shipment_by_week`, params)
  },
  obtainAgentFeesExcel(params) {
    return http.get(`${testUrl}/obtain_agent_fees_excel`, params)
  },
  statisticAgentShipment(params) {
    return http.get(`${testUrl}/statistic_agent_shipment`, params)
  },
  selfPickupStatisticInfo(params) {
    return http.get(`${testUrl}/self_pickup_statistic_info`, params)
  },
  selfPickupShipments(params) {
    return http.get(`${testUrl}/self_pickup_shipments`, params)
  },
  statisticPickupArrived(params) {
    return http.get(`${testUrl}/statistic_pickup_arrived`, params)
  },
  statisticPickupToArrive(params) {
    return http.get(`${testUrl}/statistic_pickup_to_arrive`, params)
  },
  statisticPickupHistory(params) {
    return http.get(`${testUrl}/statistic_pickup_history`, params)
  },
  statisticPickupShipments(params) {
    return http.get(`${testUrl}/statistic_pickup_shipments`, params)
  },
  statisticsSelfAgentsByYearmonth(params) {
    return http.get(`${testUrl}/statistics_self_agents_by_yearmonth`, params)
  },
  statisticSelfAgentByYearmonth(params) {
    return http.get(`${testUrl}/statistic_self_agent_by_yearmonth`, params)
  },
  statisticSelfAgentByYearmonthExcel(params) {
    return http.get(`${testUrl}/statistic_self_agent_by_yearmonth_excel`, params)
  },
}
