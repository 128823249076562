/* eslint-disable */
import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

axios.defaults.timeout = 50000
// axios.defaults.baseURL = 'https://api.ebaoguo.com'
// axios.defaults.withCredentials = true

const http = {
  post: null,
  get: null,
}

// TODO: 错误处理,token

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken')
    if (token && config.url.indexOf('login') < 0) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  err => Promise.reject(err),
)

axios.interceptors.response.use(
  res => {
    if(res.data.code === 401) {
      router.push('/login')
    }
    if(res.data.code === 500) {
      if(res.data.data.info && res.data.data.info.indexOf('401') > 0) {
        router.push('/login')
      } else {
        // 网络错误处理
        Vue.prototype.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: JSON.stringify(res.data.data.info),
            variant: 'danger',
            hideClose: false
          },
        },
        {
          position: 'top-center',
          timeout: 1000000000000,
        })
      }
    }
    return res.data;
  },
  error => {
    Promise.reject(error)
    if(error.response.status === 401) {
      router.push('/login')
      return
    } 
    if(error.response.status === 404) {
      router.push('/error-404')
      return
    }
    if(error.response.status === 500) {
      router.push('/error-500')
      return
    } 
  }
  ,
)

// isSerialize是否开启表单序列化
http.post = function post (url,
  params, isSerialize = false) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: isSerialize ? JSON.stringify(params) : params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

http.get = function (url,
  params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
      reject(err)
    })
  })
}

export default http
