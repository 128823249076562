import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'loading-page' } },
    {
      path: '/agent/pre',
      name: 'agentpre',
      component: () => import('@/views/agent/index.vue'),
    },
    {
      path: '/agent/submit/:id',
      name: 'agentsubmit',
      component: () => import('@/views/agent/newSubmit.vue'),
      meta: {
        navActiveLink: 'agentpre',
        pageTitle: '提交运单',
        breadcrumb: [
          {
            text: '提交运单',
            active: true,
          },
        ],
      },
    },
    {
      path: '/batch',
      name: 'batch',
      component: () => import('@/views/batch/index.vue'),
      meta: {
        navActiveLink: 'batch',
        pageTitle: '批量处理预报运单',
        breadcrumb: [
          {
            text: '批量处理预报运单',
            active: true,
          },
        ],
      },
    },
    {
      path: '/shipment/list',
      name: 'shipmentlist',
      component: () => import('@/views/shipment/list.vue'),
      meta: {
        navActiveLink: 'shipmentlist',
        // contentRenderer: 'sidebar-left-detached',
        // contentClass: 'ecommerce-application',
        pageTitle: '运单列表',
        breadcrumb: [
          {
            text: '运单列表',
            active: true,
          },
        ],
      },
    },
    {
      path: '/shipment/detail/:id',
      name: 'shipmentdetail',
      component: () => import('@/views/shipment/detail.vue'),
      meta: {
        navActiveLink: 'shipmentlist',
        pageTitle: '运单详情',
        breadcrumb: [
          {
            text: '运单列表',
            to: '/shipment/list',
          },
          {
            text: '运单详情',
            active: true,
          },
        ],
      },
    },
    {
      path: '/shipment/editdetail/:id',
      name: 'shipmentdetail',
      component: () => import('@/views/shipment/editDetail.vue'),
      meta: {
        navActiveLink: 'shipmentlist',
        pageTitle: '编辑运单',
        breadcrumb: [
          {
            text: '运单列表',
            active: true,
          },
          {
            text: '运单详情',
            active: true,
          },
        ],
      },
    },
    {
      path: '/seatrans/list',
      name: 'seatranslist',
      component: () => import('@/views/seatrans/list.vue'),
    },
    {
      path: '/seatrans/put',
      name: 'seatransput',
      component: () => import('@/views/seatrans/put.vue'),
    },
    {
      path: '/seatrans/putnext',
      name: 'seatransputnext',
      component: () => import('@/views/seatrans/putnext.vue'),
      meta: {
        navActiveLink: 'seatransput',
      },
    },
    {
      path: '/seatrans/pick',
      name: 'seatranspick',
      component: () => import('@/views/seatrans/pick.vue'),
    },
    {
      path: '/seatrans/newpick',
      name: 'seatransnewpick',
      component: () => import('@/views/seatrans/newPick.vue'),
      meta: {
        pageTitle: '海运取件',
        breadcrumb: [
          {
            text: '海运取件',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adminstatistics',
      name: 'adminstatistics',
      component: () => import('@/views/seatrans/adminstatistics.vue'),
      meta: {
        pageTitle: '管理员统计',
        breadcrumb: [
          {
            text: '管理员统计',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adminstatistics/detail/:id',
      name: 'adminstatisticsdetail',
      component: () => import('@/views/seatrans/adminstatisticsdetail.vue'),
      meta: {
        navActiveLink: 'adminstatistics',
        pageTitle: '管理员统计',
        breadcrumb: [
          {
            text: '管理员统计',
            active: true,
          },
        ],
      },
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('@/views/seatrans/statistics.vue'),
      meta: {
        pageTitle: '代理点统计',
        breadcrumb: [
          {
            text: '代理点统计',
            active: true,
          },
        ],
      },
    },
    {
      path: '/statistics/detail',
      name: 'statisticsdetail',
      component: () => import('@/views/seatrans/statisticsdetail.vue'),
      meta: {
        navActiveLink: 'statistics',
        pageTitle: '代理点统计',
        breadcrumb: [
          {
            text: '代理点统计',
            to: '/statistics',
          },
          {
            text: '查看',
            active: true,
          },
        ],
      },
    },
    {
      path: '/selfstatistics',
      name: 'selfstatistics',
      component: () => import('@/views/seatrans/selfstatistics.vue'),
      meta: {
        pageTitle: ' 海运统计',
        breadcrumb: [
          {
            text: ' 海运统计',
            active: true,
          },
        ],
      },
    },
    {
      path: '/selfstatistics/detail/:id',
      name: 'selfstatisticsdetail',
      component: () => import('@/views/seatrans/selfstatisticsdetail.vue'),
      meta: {
        navActiveLink: 'selfstatistics',
        pageTitle: ' 海运统计',
        breadcrumb: [
          {
            text: ' 海运统计',
            to: '/selfstatistics',
          },
          {
            text: ' 查看',
            active: true,
          },
        ],
      },
    },
    {
      path: '/seatrans/pickdetail',
      name: 'seatranspickdetail',
      component: () => import('@/views/seatrans/pickdetail.vue'),
      meta: {
        navActiveLink: 'seatranspick',
      },
    },
    {
      path: '/bill/list',
      name: 'billlist',
      component: () => import('@/views/bill/list.vue'),
      meta: {
        navActiveLink: 'billlist',
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: '账单列表',
        breadcrumb: [
          {
            text: '账单列表',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bill/detail/:id',
      name: 'billlist',
      component: () => import('@/views/bill/detail.vue'),
      meta: {
        navActiveLink: 'billdetail',
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: '账单列表',
      },
    },
    {
      path: '/account',
      name: 'accountsettings',
      component: () => import('@/views/account/index.vue'),
      meta: {
        navActiveLink: 'account',
        pageTitle: '账户设置',
        breadcrumb: [
          {
            text: ' 账户设置',
            active: true,
          },
        ],
      },
    },
    {
      path: '/logistics',
      name: 'logisticssettings',
      component: () => import('@/views/logistics/index.vue'),
      meta: {
        navActiveLink: 'logisticssettings',
        pageTitle: '物流设置',
      },
    },
    {
      path: '/logisticsdetail/:id',
      name: 'logisticsdetail',
      component: () => import('@/views/logistics/detail.vue'),
      meta: {
        navActiveLink: 'logisticssettings',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/loading',
      name: 'loading-page',
      component: () => import('@/views/error/LoadingPage.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-500',
      name: 'error-500',
      component: () => import('@/views/error/Error500.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  // const appLoading = document.getElementById('loading-bg')
  // if (appLoading) {
  //   appLoading.style.display = 'none'
  // }
})

export default router
